import React, { useEffect, useState } from "react";
import { useTable, useSortBy, useFilters, useGlobalFilter } from "react-table";
import {
  ChakraProvider, Table, Thead, Tbody, Tr, Th, Td, chakra, Flex, Spacer, Heading, Switch, Text, Box,
  Tabs, TabList, TabPanels, Tab, TabPanel
} from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import SearchPrice from './SearchPrice'
import TableHistMzl from './TableHistMzl'


// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id }
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (id === 'name') {
        options.add(row.values[id].substring(0, row.values[id].indexOf(' ')));
      } else {
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      style={{ width: "60px" }}
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Все</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}


function CustomTable({ columns, data }) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'dohod_pos_mzl',
            desc: true
          }
        ],
        hiddenColumns: ["pzy", "zak_zas_pos", "zasor_pos", "data", "pr_vip_pos", "pr_vip_pok"]
      }
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        ...columns,
      ]);
    }
  );

  function showAll() {
    allColumns.find((item) => item.id === "zasor_pos").toggleHidden();
    allColumns.find((item) => item.id === "zak_zas_pos").toggleHidden();
    allColumns.find((item) => item.id === "pzy").toggleHidden();
    allColumns.find((item) => item.id === "data").toggleHidden();
  };

  function showVip() {
    allColumns.find((item) => item.id === "pr_vip_pos").toggleHidden();
    allColumns.find((item) => item.id === "pr_vip_pok").toggleHidden();
  };

  function showMzl() {
    allColumns.find((item) => item.id === "dohod_pos_mzl").toggleHidden();
    allColumns.find((item) => item.id === "dohod_pok_mzl").toggleHidden();
  };

  return (
    <>
      <Flex position='relative' width='70%'>
        <Spacer />
        <Box p={2}>
          <Text>
            Вся инфо
          </Text>
          <Switch colorScheme='yellow' onChange={showAll} />
        </Box>
        <Box p={2}>
          <Text>
            Цены МЗЛ
          </Text>
          <Switch colorScheme='red' onChange={showMzl} defaultChecked />
        </Box>
        <Box p={2}>
          <Text>
            Цены VIP
          </Text>
          <Switch colorScheme='facebook' onChange={showVip} />
        </Box>
        <Spacer />
      </Flex>
      <Table {...getTableProps()} width='35%' variant='striped' fontSize="small">
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th key={column.id}
                  {...column.getHeaderProps([
                    column.getSortByToggleProps(),
                    {
                      style: column.style,
                    }
                  ])}
                  isNumeric={column.isNumeric} sx={{bg: '#e8e2db', textAlign: 'center', border:'0.5px solid black',
                    p:'4px', fontSize:"x-small"}}
                >
                  {column.render('Header')}
                  <chakra.span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon aria-label='sorted descending' />
                      ) : (
                        <TriangleUpIcon aria-label='sorted ascending' />
                      )
                    ) : column.canSort ? (
                        <Text>⬍</Text>
                    ) : null}
                  </chakra.span>
                  {/* Render the columns filter UI */}
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
          prepareRow(row)
          return (
            <Tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}
                  sx={{padding: '4px'}}>
                  {cell.render('Cell')}
                </Td>
              ))}
            </Tr>
          )
        })}
        </Tbody>
      </Table>
    </>
  );
}

export default function App() {

  const columns = React.useMemo(
    () => [
      {
        Header: 'ИНФО',
        columns: [
          {
            Header: 'Ко',
            accessor: 'name',
            disableSortBy: true,
            Filter: SelectColumnFilter,
            Cell: ({ row }) => {
              if (row.original.stop_otgr === 1) {
                return <div style={{ backgroundColor: "red", whiteSpace: 'normal' }}>
                  {row.original.name}
                </div>;
              } else {
                return (
                  <div style={{ whiteSpace: 'normal' }}>{row.original.name}</div>
                )
              }
            }
          },
          {
            Header: 'ПЗУ',
            accessor: 'pzy',
            disableSortBy: true,
            Filter: SelectColumnFilter,
          },
          {
            Header: 'Дата изм.',
            accessor: 'data',
            disableFilters: true,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header: 'Вид лома',
            accessor: 'vid_loma',
            disableSortBy: true,
            Filter: SelectColumnFilter,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header: 'Засор Реал.',
            accessor: 'zasor_pos',
            disableFilters: true,
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },
          {
            Header: 'Засор Закуп',
            accessor: 'zak_zas_pos',
            disableFilters: true,
            Cell: ({ row }) => {
              return <div style={{ textAlign: "center" }}>
                {(() => {
                  if (row.original.zak_zas === 0 && row.original.stop_otgr !== 1) {
                    return (
                      <div>{row.original.zak_zas_pos}</div>
                    )
                  } else if (row.original.stop_otgr === 1) {
                    return (
                      <div>СТОП</div>
                    )
                  } else {
                    return (
                      <div>По факту</div>
                    )
                  }
                })()}
              </div>;
            }
          },
        ],
        style: {backgroundColor: '#fab95b', color: 'black'},
      },
      {
        Header: 'МЗЛ',
        columns: [
          {
            Header: 'Тр. Пост',
            accessor: 'dohod_pos_mzl',
            disableFilters: true,
            Cell: ({ row }) => {
              return <div style={{ textAlign: "center" }}>
                {row.original.stop_otgr === 1 ? "СТОП" :
                (row.original.dohod_pos_mzl < 0) ? 0 :
                row.original.dohod_pos_mzl.toLocaleString('de-CH')}
              </div>;
            }
          },
          {
            Header: 'Тр. Покуп',
            accessor: 'dohod_pok_mzl',
            sortInverted: true,
            disableFilters: true,
            Cell: ({ row }) => {
              return <div style={{ textAlign: "center" }}>
                {row.original.stop_otgr === 1 ? "СТОП" :
                (row.original.dohod_pok_mzl < 0) ? 0 :
                row.original.dohod_pok_mzl.toLocaleString('de-CH')}
              </div>;
            }
          },
        ],
        style: {backgroundColor: '#f5564e', color: 'white'},
      },
      {
        Header: 'VIP',
        columns: [
          {
            Header: 'Тр. Пост',
            accessor: 'pr_vip_pos',
            sortInverted: true,
            disableFilters: true,
            Cell: ({ row }) => {
              return <div style={{ textAlign: "center" }}>
                {row.original.stop_otgr === 1 ? "СТОП" :
                (row.original.pr_vip_pos < 0) ? 0 :
                row.original.pr_vip_pos.toLocaleString('de-CH')}
              </div>;
            }
          },
          {
            Header: 'Тр. Покуп',
            accessor: 'pr_vip_pok',
            sortInverted: true,
            disableFilters: true,
            Cell: ({ row }) => {
              return <div style={{ textAlign: "center" }}>
                {row.original.stop_otgr === 1 ? "СТОП" :
                (row.original.pr_vip_pok < 0) ? 0 :
                row.original.pr_vip_pok.toLocaleString('de-CH')}
              </div>;
            }
          },
        ],
        style: {backgroundColor: '#1a3263', color: 'white'},
      },
      {
        accessor: "history",
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }) => (
           <TableHistMzl idstr={row.original.id}/>
        )
      },
    ],
    [],
  )

  const current = new Date();
  const [user, setUser] = useState("");
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;

  const [data_m, setData_m] = useState([]); // use an empty array as initial value
  const [data_m1, setData_m1] = useState([]);
  const [data_m2, setData_m2] = useState([]);
  const handleChange = values => {
     setData_m(values);
  }
  const handleChange1 = values => {
     setData_m1(values);
  }
  const handleChange2 = values => {
     setData_m2(values);
  }

  const checkUser = async () => {
    let username;
    let us_id = Number(localStorage.getItem('session'))
    if (localStorage.getItem('me3To_keN9rud')) {
      username = 'Администратор';
    } else if (localStorage.getItem('me3To_ke_uch')){
      username = 'Сотрудник';
    } else if (localStorage.getItem('me3To_ke_disp')){
      username = 'Диспетчер';
      setUser('Диспетчер');
    } else {
      username = 'Менеджер';
    }
    const response = await fetch(`/api/check_auth/${us_id}`, {method: "GET"})
    const data = await response.json()
    if (data.data.length === 0) {
      if (username === 'Администратор') {
        localStorage.removeItem('me3To_keN9rud')
        localStorage.removeItem('session')
      } else if (username === 'Сотрудник' || us_id === '1111657203') {
        localStorage.removeItem('me3To_ke_uch')
        localStorage.removeItem('session')
      } else if (username === 'Диспетчер') {
        localStorage.removeItem('me3To_ke_disp')
        localStorage.removeItem('session')
      } else {
        localStorage.removeItem('me3To_keN9')
        localStorage.removeItem('session')
      }
    }
  };

  const fetchPrice = async () => {
    const response = await fetch("/api/mzl")
    const data_m = await response.json()
    setData_m(data_m.data)

    const response1 = await fetch("/api/mzl_only_card")
    const data_m1 = await response1.json()
    setData_m1(data_m1.data)

    const response2 = await fetch("/api/mzl_not_card")
    const data_m2 = await response2.json()
    setData_m2(data_m2.data)
  }

  useEffect(() => {
    fetchPrice();
    checkUser();
  }, []);

  return (
    <ChakraProvider>
      <Flex width='50%'>
        <Heading p='2' as='h4' size='md'>
          Дата: {date}
        </Heading>
        <Spacer />
        {user === 'Диспетчер' ? (
          <SearchPrice
            handleChange={handleChange} handleChange1={handleChange1} handleChange2={handleChange2} user={'disp'}
          />
        ) : (
          <SearchPrice
            handleChange={handleChange} handleChange1={handleChange1} handleChange2={handleChange2} user={'mzl'}
          />
        )}
      </Flex>
      <Tabs isFitted >
        <TabList position='relative' width='50%' whiteSpace='nowrap'>
          <Tab _selected={{ color: 'white', bg: 'orange.600' }}>К/П</Tab>
          <Tab _selected={{ color: 'white', bg: 'orange.500' }}>К/0</Tab>
          <Tab _selected={{ color: 'white', bg: 'orange.400' }}>0/0</Tab>
        </TabList>
        <TabPanels overflowX='scroll'>
          <TabPanel>
            <CustomTable columns={columns} data={data_m} />
          </TabPanel>
          <TabPanel>
            <CustomTable columns={columns} data={data_m1} />
          </TabPanel>
          <TabPanel>
            <CustomTable columns={columns} data={data_m2} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </ChakraProvider>
  );
}